import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { get_root_value } from "src/utils/domUtils";
import { convertToTitleCase } from "src/utils/convertHtml";

export default function ActiveLastBreadcrumb({ breadCrumbMenu }) {
  const navigate = useNavigate();
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {breadCrumbMenu.map((item, index) => {
          return (
            <Link
              underline="hover"
              color={
                item.active == true
                  ? get_root_value("--portal-main-color")
                  : "white"
              }
              onClick={() => navigate(item?.navigation)}
            >
              <span style={{ cursor: "pointer" }}>
                {convertToTitleCase(item?.title)}
              </span>
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
